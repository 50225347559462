import * as ActionTypes from './actionTypes';
import template from 'url-template';
import { wennsoftResultBaseApi } from '../apis/core';

import showToastMessage from '../helpers/toastHelper';
import awsHeader from '../helpers/awsAuth';
import { handle403ErrorMessage, handle422ErrorMessage } from '../helpers/errorHelper';

const SaveConfigurationUrl = template.parse('/users/{userId}/user-views');
const GetConfigurationUrl = template.parse('/users/{userId}/user-views{?viewKey,entityId}');
const UpdateOrDeleteViewUrl = template.parse('/user-views/{userViewId}');

export const setConfigurationData = payload => ({
    type: ActionTypes.FETCH_CONFIGURATION_DATA,
    payload
});

export const saveConfigurationData = (userId, body) => async (dispatch, getState) => {
    return null;
};

export const fetchConfigurations = (userId, t, entityId, viewKey) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const result = wennsoftResultBaseApi.get(
            GetConfigurationUrl.expand({
                userId,
                entityId,
                viewKey
            }),
            header
        )
            .then(response => {
                if (response.status === 200) {
                    const { data } = response;
                    dispatch({
                        type:    ActionTypes.FETCH_CONFIGURATION_DATA,
                        payload: data
                    });

                    const userSettings = data.find(d => d.viewKey === 'Time|UserSettings');
                    dispatch({
                        type:    ActionTypes.FETCH_USER_SETTINGS,
                        payload: userSettings
                    });
                    return true;
                }
                else if (response.status === 422) {
                    const { data } = response.data;
                    handle422ErrorMessage(data);
                    return false;
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                    return false;
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    return false;
                }
            });

        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
    }

};

export const saveConfiguration = (userId, body, t, disableToastMessage = false) => async (dispatch, getState) => {
    const header = await awsHeader();
    try {
        const result = wennsoftResultBaseApi.post(
            SaveConfigurationUrl.expand({
                userId
            }),
            body,
            header
        )
            .then(response => {
                if (response.status === 200 || response.status === 201 || response.status === 204) {
                    if (!disableToastMessage)
                        showToastMessage(t('time.settingsSaved'), 'success');
                    const id = response.data.id;

                    dispatch(fetchConfigurations(userId, t));

                    return {success: true, id: id};
                }
                else if (response.status === 422) {
                    const { data } = result.data;
                    handle422ErrorMessage(data);
                    return {success: false, id: null};
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                    return {success: false, id: null};
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    return {success: false, id: null};
                }
            });
        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
        return {success: false, id: null};
    }
};

export const udpateConfiguration = (userViewId, body, t, disableToastMessage = false) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const result = wennsoftResultBaseApi.put(
            UpdateOrDeleteViewUrl.expand({
                userViewId
            }),
            body,
            header
        )
            .then(response => {
                if (response.status === 200 || response.status === 201 || response.status === 204) {
                    const payload = response.data;
                    let allConfigurations = getState().configurations.allConfigurations;
                    const configIndex = allConfigurations.findIndex(c => c.id === payload.id);
                    if (configIndex !== -1) {
                        allConfigurations[configIndex] = payload;
                    }

                    dispatch({
                        type:    ActionTypes.FETCH_CONFIGURATION_DATA,
                        payload: allConfigurations
                    });

                    dispatch({
                        type:    ActionTypes.FETCH_USER_SETTINGS,
                        payload: payload
                    });

                    if (!disableToastMessage) {
                        showToastMessage(t('time.settingsSaved'), 'success');
                    }
                    return true;
                }
                else if (response.status === 422) {
                    handle422ErrorMessage(response.data);
                    return false;
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                    return false;
                }
                else {
                    showToastMessage(response.statusText, 'error');
                }
            });
        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
        return false;
    }
};
