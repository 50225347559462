import * as ActionTypes from './actionTypes';
import template from 'url-template';
import { get, orderBy } from 'lodash';
import awsHeader from '../helpers/awsAuth';
import wennsoftBaseApi, { wennsoftResultBaseApi } from '../apis/core';
import { ConcurrencyManager } from 'axios-concurrency';

import showToastMessage from '../helpers/toastHelper';
import { handle422ErrorMessage, handle403ErrorMessage } from '../helpers/errorHelper';

const employeeUrl = template.parse('/employees{?managerId,page,limit,includeNonTimeUsers}');
const employeeUpdateUrl = template.parse('/employees/{employeeId}');
const employeePayCodesUrl = template.parse('/employees/{employeeId}/employee-paycodes{?page,limit}');
const employeeDelegateUrl = template.parse('/employee-delegate{?employeeId,delegateId}');
const employeeDelegatesUrl = template.parse('/employee-delegates');

const DEFAULT_PAGE_SIZE = 200;
const MAX_CONCURRENCY_REQUESTS = 10;

export const fetchEmployeesList = (managerId, options = {}) => async (dispatch, getState) => {
    const header = await awsHeader();

    options.limit = DEFAULT_PAGE_SIZE;
    options.page = 1;

    const manager = ConcurrencyManager(wennsoftBaseApi, MAX_CONCURRENCY_REQUESTS);
    try {
        const response = await wennsoftBaseApi.get(
            employeeUrl.expand({
                managerId,
                ...options
            }),
            header
        );

        let data = get(response, 'data.data', []);
        const meta = get(response, 'data.meta', {});
        const totalCount = meta.totalCount;

        if (!data) {
            data = [];
        }

        let totalPages = totalCount / options.limit;
        let count = totalPages;
        if (!Number.isInteger(totalPages)) {
            count = totalPages + 1;
        }

        let promises = [];
        for (let i = 2; i <= count; i++) {
            options.page = i;
            promises.push(
                wennsoftBaseApi.get(
                    employeeUrl.expand({
                        managerId,
                        ...options
                    }),
                    header
                )
            );
        }

        let results = [];
        await Promise.all(promises.map(p => p))
            .then(result => {
                result.forEach(r => results.push(r.data.data));
            });


        let payload = data.concat(...results);

        payload.forEach((emp, index) => {
            emp.fullName = `${emp.firstName} ${emp.lastName}`;
            emp.displayName = `${emp.lastName}, ${emp.firstName}`;
        });

        payload = orderBy(payload, ['firstName', 'lastName'], ['asc', 'asc']);

        if (!options.includeNonAdminUsers && !options.includeNonTimeUsers) {
            dispatch({
                type: ActionTypes.FETCH_EMPLOYEE_LIST,
                payload
            });
        }
        return payload;
    }
    catch (error) {
        if (!options.includeNonAdminUsers && !options.includeNonTimeUsers) {
            dispatch({
                type:    ActionTypes.FETCH_EMPLOYEE_LIST,
                payload: []
            });
        }
        showToastMessage(error, 'error');
        return [];
    }
    finally {
        manager.detach();
    }
};

export const fetchEmployeePayCodes = (employeeId, options = {}) => async(dispatch, getState) => {
    const header = await awsHeader();

    options.limit = DEFAULT_PAGE_SIZE;
    options.page = 1;

    const manager = ConcurrencyManager(wennsoftBaseApi, MAX_CONCURRENCY_REQUESTS);
    try {
        const response = await wennsoftBaseApi.get(
            employeePayCodesUrl.expand({
                employeeId,
                options
            }),
            header
        );

        const data = get(response, 'data.data', []);
        const meta = get(response, 'data.meta', {});
        const totalCount = meta.totalCount;

        let totalPages = totalCount / options.limit;
        let count = totalPages;
        if (!Number.isInteger(totalPages)) {
            count = totalPages + 1;
        }

        let promises = [];
        for (let i = 2; i <= count; i++) {
            options.page = i;
            promises.push(
                wennsoftBaseApi.get(
                    employeePayCodesUrl.expand({
                        employeeId,
                        ...options
                    }),
                    header
                )
            );
        }

        let results = [];
        await Promise.all(promises.map(p => p))
            .then(result => {
                result.forEach(r => results.push(r.data.data));
            });

        const payloadData = orderBy(data.concat(...results), 'paycodeName','asc');
        payloadData.forEach(pc => {
            switch (pc.payType) {
                case 1:
                case 2:
                    pc.groupTypeOrder = 1;
                    pc.groupType = 'time.regularPayCodes'; //'Regular Pay Codes';
                    break;
                default:
                    pc.groupTypeOrder = 2;
                    pc.groupType = 'time.otherPayCodes'; // 'Other Pay Codes';
            }
        });

        const payload = orderBy(payloadData, ['groupTypeOrder', 'paycodeName'], ['asc', 'asc']);

        if (options.userSettingsFetch) {
            return payload;
        }
        else {
            dispatch({
                type: ActionTypes.SET_EMPLOYEE_PAYCODES,
                payload
            });
        }
    }
    catch (error) {
        dispatch({
            type:    ActionTypes.SET_EMPLOYEE_PAYCODES,
            payload: []
        });
        showToastMessage(error, 'error');
    }
    finally {
        manager.detach();
    }

};

export const getEmployeeDelegates = (t) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const result = await wennsoftResultBaseApi.get(
            employeeDelegatesUrl.expand({}),
            header
        )
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type:    ActionTypes.FETCH_EMPLOYEE_DELEGATE_LIST,
                        payload: response.data
                    });
                    return true;
                }
                else if (response.status === 422) {
                    const { data } = response;
                    handle422ErrorMessage(data);
                    dispatch({
                        type:    ActionTypes.FETCH_EMPLOYEE_DELEGATE_LIST,
                        payload: []
                    });
                    return false;
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                    dispatch({
                        type:    ActionTypes.FETCH_EMPLOYEE_DELEGATE_LIST,
                        payload: []
                    });
                    return false;
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    dispatch({
                        type:    ActionTypes.FETCH_EMPLOYEE_DELEGATE_LIST,
                        payload: []
                    });
                    return false;
                }
            })
            .catch(error => {
                showToastMessage(error, 'error');
                dispatch({
                    type:    ActionTypes.FETCH_EMPLOYEE_DELEGATE_LIST,
                    payload: []
                });
                return false;
            });

        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
        return false;
    }
};

export const createEmployeeDelegate = (employeeId, delegateEmployeeId, t) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const body = {
            employeeId,
            delegateEmployeeId
        };

        const result = await wennsoftResultBaseApi.post(
            employeeDelegateUrl.expand({}),
            body,
            header
        )
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    showToastMessage(t('time.managerAssigned'), 'success');
                    return true;
                }
                else if (response.status === 422) {
                    const { data } = response;
                    handle422ErrorMessage(data);
                    return false;
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                    return false;
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    return false;
                }            })
            .catch(error => {
                showToastMessage(error,'error');
                return false;
            });

        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
        return false;
    }
};

export const deleteEmployeeDelegate = (employeeId, delegateId, t) => async (dispatch, getState) => {
    const header = await awsHeader();

    try {
        const result = await wennsoftResultBaseApi.delete(
            employeeDelegateUrl.expand({employeeId, delegateId}),
            header
        )
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    showToastMessage(t('time.managerRemoved'), 'success');
                    return true;
                }
                else if (response.status === 422) {
                    const { data } = response;
                    handle422ErrorMessage(data);
                    return false;
                }
                else if (response.status === 403) {
                    handle403ErrorMessage(t);
                    return false;
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    return false;
                }
            })
            .catch(error => {
                showToastMessage(error,'error');
                return false;
            });

        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
        return false;
    }
};

export const updateEmployeeLicensing = (employeeId, body, t) => async(disptach, getState) => {
    const header = await awsHeader();

    try {
        const result = wennsoftResultBaseApi.put(
            employeeUpdateUrl.expand({
                employeeId
            }),
            body,
            header
        )
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    showToastMessage(t('time.employeeUpdated'), 'success');
                    return true;
                }
                else if (response.status === 422) {
                    const { data } = response;
                    handle422ErrorMessage(data);
                    return false;
                }
                else if (response.status === 403) {
                    showToastMessage(t('common.userNotAuthorized'), 'error');
                    return false;
                }
                else {
                    showToastMessage(response.statusText, 'error');
                    return false;
                }
            });

        return result;
    }
    catch (error) {
        showToastMessage(error, 'error');
        return false;
    }

};

export const setActiveEmployee = (employee) => async (dispatch, getState) => {
    dispatch({
        type:    ActionTypes.SET_ACTIVE_EMPLOYEE,
        payload: employee
    });
};

export const clearEmployeeList = () => async (dispatch, getState) => {
    dispatch({
        type:    ActionTypes.FETCH_EMPLOYEE_LIST,
        payload: []
    });
    dispatch({
        type:    ActionTypes.SET_EMPLOYEE_PAYCODES,
        payload: []
    });
};

export const clearEmployeePayCodes = () => async (dispatch, getState) => {
    dispatch({
        type:    ActionTypes.SET_EMPLOYEE_PAYCODES,
        payload: []
    });
};
