import React, { useEffect, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DropDownButton from 'devextreme-react/drop-down-button';
import { isEmpty, isEqual } from 'lodash';
import { usePrevious } from '../../hooks/usePrevious';
import ConfirmationDialog from '../confirmationDialog/confirmationDialog.js';

import * as CompanyInfoActions from '../../actions/companyInfoActions';
import * as CoreActions from '../../actions/coreActions';
import * as EmployeeActions from '../../actions/employeeActions';
import * as TimeActions from '../../actions/timeActions';

import './CompaniesPanel.scss';

const CompaniesPanel = ({
    activeCompany,
    clearEmployeeList,
    clearEmployeeTimesheet,
    currentOS,
    fetchCompanyInfo,
    isInEditMode,
    parentToolbar,
    setActiveCompany,
    setActiveEmployee,
    setBootstrapping,
    setCurrentTimeSheet,
    timeCompanies,
    updateDefaultCompany,
    ...props
}) => {
    const { t } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedCompany, setSelectedCompany] = useState();
    const [companyList, setCompanyList] = useState([]);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const prevTimeCompanies = usePrevious(timeCompanies);

    useEffect(() => {
        if (!isEqual(prevTimeCompanies, timeCompanies)) {
            if (!isEmpty(timeCompanies)) {
                const list = timeCompanies.map(c => {
                    return c.company;
                });
                setCompanyList(list);
            }
        }
    }, [timeCompanies]);

    useEffect(() => {
        setSelectedCompany(activeCompany);
        const title = activeCompany && activeCompany.name ? `Time - ${activeCompany.name}` : 'Time';
        document.title = title;
    }, [activeCompany.id]);

    const onValueChanged = async e => {
        const { itemData } = e;
        if (activeCompany.id === itemData.id) {
            return;
        }

        if (!isInEditMode) {
            await clearEmployeeList();
            const active = {id: null};
            setActiveEmployee(active);

            const company = timeCompanies.find(c => c.company.id === itemData.id);
            sessionStorage.setItem('companyId', itemData.id);
            await updateDefaultCompany(itemData.id, t);
            await fetchAuthSession({forceRefresh: true});
            setBootstrapping(true);
            clearEmployeeTimesheet();
            await fetchCompanyInfo(company);

            fetchCompanyInfo(company).then(result => {
                // clear out redux store data
                setSearchParams({companyid: itemData.id});
                setActiveCompany(itemData);
                if (parentToolbar.current?.instance) {
                    parentToolbar.current?.instance.repaint();
                }
            });
        }
        else {
            setShowConfirmationDialog(true);
        }
    };

    return (
        <>
            <DropDownButton
                text={selectedCompany?.name}
                icon={'fa-regular fa-building'}
                items={companyList}
                displayExpr={'name'}
                valueExpr={'id'}
                onItemClick={onValueChanged}
                showArrowIcon={false}
                dropDownOptions={{
                    minWidth:  '225px',
                    maxHeight: '40vh'
                }}
            />
            { showConfirmationDialog ?
                <ConfirmationDialog
                    showDialog={showConfirmationDialog}
                    confirmationTitle={'Record in Edit'}
                    denyButtonAction={() => {setShowConfirmationDialog(false);}}
                    denyButtonText="Close"
                    dialogMessage={'You are currently editing a time record. Please complete the edit before changing companies.'}
                    showConfirmButton={false}
                    defaultButton={'cancel'}
                />
                : null
            }
        </>
    );
};

const mapStateToProps = state => ({
    activeCompany: state.time.activeCompany,
    companies:     state.core.companies,
    companyInfo:   state.companyInfo,
    currentOS:     state.core.currentOS,
    currentUser:   state.currentUser,
    instanceId:    state.auth.instanceId,
    isInEditMode:  state.time.isInEditMode,
    session:       state.auth.session,
    timeCompanies: state.core.timeCompanies
});

const mapDispatchToProps = {
    ...CompanyInfoActions,
    ...CoreActions,
    ...EmployeeActions,
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesPanel);
