import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import SelectBox from 'devextreme-react/select-box';
import { useTranslation } from 'react-i18next';

import * as EmployeeActions from '../../actions/employeeActions';

import './EmployeeLookup.scss';

const EmployeeLookup = ({
    currentUser,
    employeeList,
    fetchEmployeePayCodes,
    selectedEmployee,
    setActiveEmployee,
    setSelectedEmployee
}) => {
    const { t } = useTranslation();

    const [activeEmployeeName, setActiveEmployeeName] = useState('');

    useEffect(() => {
        if (currentUser.id && employeeList.length !== 0) {
            const empFound = employeeList.find(emp => emp.userId === currentUser.id);
            if (empFound) {
                setSelectedEmployee(empFound.id);
                setActiveEmployee(empFound);
                setActiveEmployeeName(empFound.firstName + ' ' + empFound.lastName);
            }
            else {
                setSelectedEmployee(employeeList[0].id);
                setActiveEmployee(employeeList[0]);
                setActiveEmployeeName(employeeList[0].firstName + ' ' + employeeList[0].lastName);
            }
        }
    }, [currentUser.id]);

    const handleEmployeeChanged = useCallback((e) => {
        const employee = employeeList.find(emp => emp.id === e.value);
        if (employee) {
            setActiveEmployee(employee);
            setActiveEmployeeName(employee.firstName + ' ' + employee.lastName);
            setSelectedEmployee(e.value);
            fetchEmployeePayCodes(employee.id);
        }
    }, [employeeList]);

    const className = isEmpty(employeeList) || employeeList.length === 1 ? 'employee-name' : 'employee-list';

    const EmployeeSelectBox = () => {
        return (
            <SelectBox
                dataSource={employeeList}
                value={selectedEmployee}
                valueExpr={'id'}
                displayExpr={'fullName'}
                searchExpr={'fullName'}
                searchEnabled={true}
                searchTimeout={200}
                onValueChanged={handleEmployeeChanged}
                placeholder={t('time.selectEmployee')}
            />);
    };

    return (
        <div className={'employee-wrapper'}>
            <div className={'employee'}>{`${t('time.timeEntryFor')}:`}</div>
            <div className={className}>{isEmpty(employeeList) || employeeList.length === 1 ? activeEmployeeName : <EmployeeSelectBox />}</div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        currentUser:  state.currentUser,
        employeeList: state.employee.employeeList,
    };
};

const mapDispatchToProps = {
    ...EmployeeActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeLookup);
