import * as ActionTypes from './actionTypes';
import template from 'url-template';
import { get, orderBy } from 'lodash';

import awsHeader from '../helpers/awsAuth';
import wennsoftBaseApi from '../apis/core';
import { ConcurrencyManager } from 'axios-concurrency';

import showToastMessage from '../helpers/toastHelper';

const workOrderUrl = template.parse('workorder{?page,limit,workOrderType,workOrderStatus,externalOriginOnly}');
const workOrderCostCodesUrl = template.parse('costcodeservices');

const DEFAULT_PAGE_SIZE = 200;
const MAX_CONCURRENCY_REQUESTS = 10;

export const fetchWorkOrderList = (companyId, options = {}) => async(dispatch, getState) => {
    const header = await awsHeader();

    options.limit = options.limit || DEFAULT_PAGE_SIZE;
    options.page = 1;
    options.externalOriginOnly = true;

    let allWorkOrders = [];
    const manager = ConcurrencyManager(wennsoftBaseApi, MAX_CONCURRENCY_REQUESTS);

    try {
        const response = await wennsoftBaseApi.get(
            workOrderUrl.expand({
                ...options
            }),
            header
        );

        const data = get(response, 'data.data', []) || [];
        const meta = get(response, 'data.meta', {});
        const totalCount = meta.totalCount;

        let totalPages = totalCount / options.limit;
        let count = totalPages;
        if (!Number.isInteger(totalPages)) {
            count = totalPages + 1;
        }

        let promises = [];
        for (let i = 2; i <= count; i++) {
            options.page = i;
            promises.push(
                wennsoftBaseApi.get(
                    workOrderUrl.expand({
                        ...options
                    }),
                    header
                )
            );
        }

        let results = [];
        await Promise.all(promises.map(p => p))
            .then(result => {
                result.forEach(r => results.push(r.data.data));
            });

        allWorkOrders = data.concat(...results);

        allWorkOrders.forEach(w => w.activityId = w.workOrderNumber);

        dispatch({
            type:    ActionTypes.SET_WORK_ORDER_LIST,
            payload: orderBy(allWorkOrders, 'workOrderNumber', 'desc')
        });
    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.SET_WORK_ORDER_LIST,
            payload: []
        });
        return [];
    }
    finally {
        manager.detach();
    }
};

export const fetchWorkOrderCostCodes = () => async(dispatch, getState) => {
    const header = await awsHeader();
    try {
        const response = await wennsoftBaseApi.get(
            workOrderCostCodesUrl.expand(
            ),
            header
        );

        const data = get(response, 'data', []) || [];

        data.forEach(cc => {
            cc.costCodeAlias = cc.customAttributes[0].customAvp.attributeValue;
        });

        dispatch({
            type:    ActionTypes.SET_WORK_ORDER_COST_CODES,
            payload: data
        });
    }
    catch (error) {
        showToastMessage(error, 'error');
        dispatch({
            type:    ActionTypes.SET_WORK_ORDER_COST_CODES,
            payload: []
        });
    }
};
