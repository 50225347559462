import React, { useCallback, useEffect, useState, useMemo, useContext, useReducer } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import devices from 'devextreme/core/devices';
import Button from 'devextreme-react/button';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import DataSource from 'devextreme/data/data_source';

// import notify from 'devextreme/ui/notify';
import { ScrollView } from 'devextreme-react';

import ChangePassword from '../../containers/Authentication/ChangePassword';
import { service } from '../../data/user-profile-service';
import { withLoadPanel } from '../../utils/withLoadPanel';
import { useScreenSize } from '../../utils/media-query';
import { ProfileCard } from '../../components/profile-card/ProfileCard';
import UserAvatar from '../../components/user-avatar/UserAvatar';

import * as ConfigurationActions from '../../actions/configurationActions';
import * as EmployeeActions from '../../actions/employeeActions';

import './UserProfile.scss';

const initialState = {
    profileData:      {},
    userSettingsData: {}
};

function reducer(state, action) {
    switch (action.type) {
        case 'profileData':
            return {
                ...state,
                profileData: action.payload
            };
        case 'userSettingsData':
            return {
                ...state,
                userSettingsData: action.payload
            };
        default:
            return state;
    }
}

const UserSettingsDispatch = React.createContext(null);

const defaultUserSettings = {
    defaultLaborPayCodeId: null
};

// const copyToClipboard = (text) => (evt) => {
//     window.navigator.clipboard?.writeText(text);
//     const tipText = 'Text copied';
//     notify(
//         {
//             message:  tipText,
//             minWidth: `${tipText.length + 2}ch`,
//             width:    'auto',
//             position: { of: evt.element, offset: '0 -30' }
//         },
//         'info',
//         500
//     );
// };

const UserProfileContent = ({
    basicInfoItems,
    employeeLaborPayCodes,
    userSettingsInfoItems,
    handleDataChanged,
    handleChangePasswordClick,
    handleContentScrolled
}) => {
    const { profileData, userSettingsData} = useContext(UserSettingsDispatch);

    const { t } = useTranslation();
    const { isXSmall } = useScreenSize();

    const onScroll = useCallback((reachedTop) => {
        handleContentScrolled(reachedTop);
    },[handleContentScrolled]);

    return (
        <ScrollView
            height={'calc(100% - 155px)'}
            className="view-wrapper-scroll"
            onScroll={onScroll}
        >
            <div className="cards-container">
                <ProfileCard
                    wrapperCssClass="profile-card basic-info-card"
                    title={t('userSettings.basicInfo')}
                    colCount={4}
                    cardData={profileData}
                    items={basicInfoItems}
                    onDataChanged={handleDataChanged}
                >
                    <div className="basic-info-top-item profile-card-top-item">
                        <UserAvatar
                            name={`${profileData?.firstName} ${profileData?.lastName}`}
                        />
                        <div>
                            <div className="title-text">{`${profileData?.firstName} ${profileData?.lastName}`}</div>
                            <div className="subtitle-text with-clipboard-copy">
                                <span>{`${t('common.email')}: ${profileData?.email}`}</span>
                                {/* <Button
                                    icon="copy"
                                    className="copy-clipboard-button"
                                    stylingMode="text"
                                    onClick={copyToClipboard(profileData?.email)}
                                    activeStateEnabled={false}
                                    focusStateEnabled={false}
                                    hoverStateEnabled={false}
                                /> */}
                            </div>
                            <Button
                                text={t('menu.changePassword')}
                                className="change-password-button"
                                stylingMode="contained"
                                icon={isXSmall ? void 0 : 'lock'}
                                onClick={handleChangePasswordClick}
                            />
                        </div>
                    </div>
                </ProfileCard>
                <ProfileCard
                    wrapperCssClass="profile-card user-settings-card"
                    title={t('userSettings.userSettings')}
                    cardData={userSettingsData}
                    employeeLaborPayCodes={employeeLaborPayCodes}
                    items={userSettingsInfoItems}
                    onDataChanged={handleDataChanged}
                >
                    <div className="profile-card-top-item">
                        <div className="image-wrapper">
                            <i className="dx-icon dx-icon-preferences" />
                        </div>
                        <div>
                            <div className="title-text">
                                {`${t('userSettings.timeSettingsFor')} ${profileData?.firstName}`}
                            </div>
                        </div>
                    </div>
                </ProfileCard>
            </div>
        </ScrollView>
    );
};

const UserProfileContentWithLoadPanel = withLoadPanel(UserProfileContent);

const UserProfile = ({
    activeCompany,
    currentUser,
    employeeList,
    fetchConfigurations,
    fetchEmployeePayCodes,
    handleContentScroll,
    saveConfiguration,
    timeUserSettings,
    udpateConfiguration,
    ...props
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    let {profileData, userSettingsData} = state;

    const { t } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams({});

    const [savedProfileData, setSavedProfileData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isChangePasswordPopupOpened, setIsChangedPasswordPopupOpened] = useState(false);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [basicInfoItems, setBasicInfoItems] = useState([]);
    const [employeeLaborPayCodes, setEmployeeLaborPayCodes] = useState([]);
    const [userSettingsInfoItems, setUserSettingsInfoItems] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [savedUserSettingsData, setSavedUserSettingsData] = useState([]);
    const [userSettingsParent, setUserSettingsParent] = useState({});
    const [isContentScrolled, setIsContentScrolled] = useState(false);

    const dataChanged = useCallback((cardData) => {
        if (cardData && cardData.card ===t('userSettings.userSettings')) {
            dispatch({type: 'userSettingsData', payload: cardData.cardData});
        }
        setIsDataChanged(true);
    }, []);

    const changePassword = useCallback(() => {
        setIsChangedPasswordPopupOpened(true);
    }, []);

    const handleContentScrolled = useCallback((reachedTop) => {
        setIsContentScrolled(!reachedTop);
    }, []);

    const setSavedData = useCallback((data = profileData) => {
        setSavedProfileData(JSON.parse(JSON.stringify(data)));
    }, [profileData]);

    const onCancel = useCallback(() => {
        dispatch({type: 'profileData', payload: cloneDeep(savedProfileData)});
        dispatch({type: 'userSettingsData', payload: JSON.parse(timeUserSettings.viewData)});
        setSavedData();
        setIsDataChanged(false);
    }, [savedProfileData, savedUserSettingsData, setSavedData, timeUserSettings]);

    const onSave = useCallback(async () => {
        setIsLoading(true);
        if (userSettingsData) {
            let result;
            const preparedBody = prepareUserSettingsForSave(userSettingsData);
            if (userSettingsParent && userSettingsParent.id) {
                result = await udpateConfiguration(userSettingsParent.id, preparedBody, t);
                if (result.success) {
                    await fetchConfigurations(currentUser.id, t);
                }
                else {
                    setIsLoading(false);
                }
            }
            else {
                result = await saveConfiguration(currentUser.id, preparedBody, t);
            }
        }
        setIsDataChanged(false);
        setSavedData();
        setIsLoading(false);
    }, [userSettingsData, setSavedData]);

    useEffect(() => {
        setIsLoading(true);
        if (activeCompany && activeCompany.id) {
            setSearchParams({companyId: activeCompany.id});
        }

        setUserSettingsInfoItems(service.getUserSettingsInfoItems);
        setBasicInfoItems(service.getBasicInfoItems(t));
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setSavedProfileData(profileData);
    }, [profileData]);

    useEffect(() => {
        const init = async () => {
            const userData = cloneDeep(currentUser);
            const userEmployee = employeeList.find(emp => emp.userId === currentUser.id);
            if (userEmployee) {
                userData.department = userEmployee.department;
                userData.position = userEmployee.position;
            }
            if (userEmployee) {
                const laborPayCodes = await fetchEmployeePayCodes(userEmployee.id, {userSettingsFetch: true});
                const groupedData = new DataSource({
                    store: laborPayCodes,
                    key:   'paycodeId',
                    group: [{selector: 'groupTypeOrder', desc: false}]
                });

                setEmployeeLaborPayCodes(groupedData);
            }
            dispatch({type: 'profileData', payload: userData});
        };

        if (currentUser.id) init();
    }, [employeeList, currentUser]);

    useEffect(() => {
        if (timeUserSettings && timeUserSettings.viewData) {
            setUserSettingsParent(timeUserSettings);
            dispatch({type: 'userSettingsData', payload: JSON.parse(timeUserSettings.viewData)});
        }
        else {
            setUserSettingsParent(defaultUserSettings);
            dispatch({type: 'userSettingsData', payload: defaultUserSettings});
        }
    }, [timeUserSettings]);

    const prepareUserSettingsForSave = settings => {
        let body = {
            name:         'Time User Settings',
            viewKey:      'Time|UserSettings',
            entityId:     activeCompany.id,
            entityName:   activeCompany.name,
            isDefault:    false,
            viewData:     JSON.stringify(settings),
            sharedEmails: []
        };

        return body;
    };

    const contextValue = useMemo(() => ({
        profileData,
        userSettingsData
    }), [
        profileData,
        userSettingsData
    ]);

    return (
        <div className="view-host user-profile">
            <div className="view-wrapper">
                <Toolbar className={`theme-dependent ${isContentScrolled ? 'scrolled' : ''}`}>
                    <Item location="before">
                        <div className="header-text">{t('menu.userProfile')}</div>
                    </Item>
                    <Item location="after" locateInMenu={devices.current().phone ? 'always' : 'auto'}>
                        <Button
                            className="cancel-button"
                            text={t('common.cancel')}
                            disabled={!isDataChanged}
                            stylingMode="outlined"
                            type="normal"
                            onClick={onCancel}
                        />
                    </Item>
                    <Item location="after" locateInMenu={devices.current().phone ? 'always' : 'auto'}>
                        <Button
                            className="save-button"
                            disabled={!isDataChanged}
                            text={t('common.save')}
                            icon="save"
                            type="default"
                            stylingMode="contained"
                            onClick={onSave}
                        />
                    </Item>
                </Toolbar>
                <UserSettingsDispatch.Provider value={contextValue}>
                    <UserProfileContentWithLoadPanel
                        basicInfoItems={basicInfoItems}
                        employeeLaborPayCodes={employeeLaborPayCodes}
                        userSettingsInfoItems={userSettingsInfoItems}
                        handleChangePasswordClick={changePassword}
                        handleDataChanged={dataChanged}
                        handleContentScrolled={handleContentScrolled}
                        hasData={!isLoading}
                        laoding={isLoading}
                        panelProps={{
                            container: '.view-wrapper',
                            position:  { of: '.content' }
                        }}
                    />
                </UserSettingsDispatch.Provider>
            </div>
            <ChangePassword
                visible={isChangePasswordPopupOpened}
                setVisible={setIsChangedPasswordPopupOpened}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    activeCompany:    state.time.activeCompany,
    currentUser:      state.currentUser,
    employeeList:     state.employee.employeeList,
    timeUserSettings: state.configurations.timeUserSettings
});

const mapDispatchToProps = {
    ...ConfigurationActions,
    ...EmployeeActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
