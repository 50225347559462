/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Beforeunload } from 'react-beforeunload';
import RequireAuth from './Authentication/RequireAuth';
import { get, isEmpty, isEqual } from 'lodash';
import WebSocketConnection from './Global/WebSocketConnection';
import getYear from 'date-fns/getYear';

import { SideNavOuterToolbar as SideNavBarLayout } from '../layouts';
import { Footer } from '../components';
import ChangePassword from './Authentication/ChangePassword';

import * as ActivitiesActions from '../actions/activitiesActions';
import * as CompanyUserActions from '../actions/companyUserActions';
import * as ConfigurationActions from '../actions/configurationActions';
import * as CoreActions from '../actions/coreActions';
import * as CurrentUserActions from '../actions/currentUserActions';
import * as CompanyInfoActions from '../actions/companyInfoActions';
import * as EmployeeActions from '../actions/employeeActions';
import * as JobActions from '../actions/jobActions';
import * as WorkOrderActions from '../actions/workOrderActions';
import * as TimeActions from '../actions/timeActions';

import routes from '../app-routes';
import AccessDenied from '../containers/AccessDenied/AccessDenied';

const Time = ({
    activeCompany,
    apiVersion,
    companies,
    searchCompanyId,
    changePasswordVisible,
    companyInfo,
    companyUser,
    currentOS,
    currentUser,
    featureFlags,
    fetchCompanyInfo,
    fetchCompanyPayCodes,
    fetchCompanyShiftCodes,
    fetchCompanyUser,
    fetchConfigurations,
    fetchEmployeesList,
    fetchJobList,
    fetchUnbilledActivities,
    fetchWorkOrderCostCodes,
    fetchWorkOrderList,
    fetchTimeSettings,
    i18n,
    initUser,
    instanceId,
    isBootStrapping,
    isInEditMode,
    isMobileApp,
    isTimeApplicationUser,
    session,
    settings,
    setActiveCompany,
    setBootstrapping,
    setChangePasswordVisible,
    setCurrentOS,
    setIsTimeApplicationUser,
    setTimesheetStartDate,
    setUserLanguage,
    t,
    timeCompanies,
    timeUserSettings
}) => {
    const [ companyId, setCompanyId ] = useState(null);
    const [ queryParams, setQueryParams] = useState();
    const [ isValidCompany, setIsValidCompany ] = useState(true);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams({});
    const navigate = useNavigate();

    const returnUrl = sessionStorage.getItem('returnUrl');


    useEffect(() => {
        if (searchCompanyId && searchCompanyId !== 'null') {
            setSearchParams({companyId: searchCompanyId});
        }

        const init = async () => {

            await setCurrentOS();
            setBootstrapping(true);
            if (!currentUser.defaultCompany) {
                await initUser();
            }
            else {
                setBootstrapping(false);
            }
            sessionStorage.removeItem('returnUrl');
        };

        init();

    }, []);

    useEffect(() => {
        const init = async () => {
            if (companyUser.userTypes) {
                const employeeType = companyUser.userTypes.find(ut => ut.usertype.toLowerCase() === 'employee');
                if (employeeType) {
                    const employeeAttribute = employeeType.userAttributes;
                    employeeAttribute.fullName = `${employeeAttribute.firstName} ${employeeAttribute.lastName}`;
                }
            }
            if (companyInfo.applications) {
                const timeApp = companyInfo.applications.find(app => app.applicationName === 'Time');
                if (timeApp) {
                    if (companyUser.userApplications || currentUser.isSuperUser) {
                        const userHasTimeAccess = companyUser.userApplications?.ids.find(a => a === timeApp.applicationId);
                        if (userHasTimeAccess || currentUser.isSuperUser) {
                            setIsTimeApplicationUser(true);
                        }
                        else {
                            setIsTimeApplicationUser(false);
                        }
                    }
                    else {
                        setIsTimeApplicationUser(false);
                    }
                }
                else {
                    setIsTimeApplicationUser(false);
                }
            }
            else {
                setIsTimeApplicationUser(false);
            }
        };
        if (!isEmpty(companyUser) && companyUser.id) init();

    }, [companyUser.id, companyUser.userTypes]);

    useEffect(() => {
        const init = async () => {
            sessionStorage.setItem('companyId', activeCompany.id);
            setSearchParams({companyId: activeCompany.id});
            if (companyInfo.roles.length !== 0) {
                const userDetails = await fetchCompanyUser(currentUser.id);
                if (userDetails.userApplications || currentUser.isSuperUser) {
                    const timeApp = companyInfo.applications.find(app => app.applicationName === 'Time');
                    const userHasTimeAccess = userDetails.userApplications?.ids.find(a => a === timeApp.applicationId);
                    if (userHasTimeAccess || currentUser.isSuperUser) {
                        setIsTimeApplicationUser(true);
                        await fetchTimeSettings();
                        const promises = [
                            setTimesheetStartDate(null),
                            fetchCompanyPayCodes(t),
                            fetchEmployeesList(),
                            fetchJobList(activeCompany.id, {limit: 2000}),
                            fetchWorkOrderList(activeCompany.id, {limit: 2000}),
                            fetchWorkOrderCostCodes(),
                            fetchUnbilledActivities(),
                            fetchCompanyShiftCodes(t),
                            fetchConfigurations(currentUser.id, t)
                        ];

                        await Promise.all(promises.map(p => p));

                    }
                    else {
                        setIsTimeApplicationUser(false);
                    }
                }
                setBootstrapping(false);
            }
            else {
                setIsTimeApplicationUser(false);
                setBootstrapping(false);
            }
        };

        if (activeCompany && activeCompany.id && currentUser && currentUser.id) init();
    }, [activeCompany.id]);

    useEffect(() => {
        const init = async () => {
            if (companies.length !== 0 && currentUser.id) {
                const initCompanyInfo = async targetCompany => {
                    const valid = await fetchCompanyInfo(targetCompany);
                    setIsValidCompany(valid);
                    return valid;
                };

                if (!isEmpty(timeCompanies)) {
                    let defaultCompany, targetCompany;

                    const targetCompanyId = searchParams.get('companyId');
                    if (targetCompanyId && targetCompanyId !== null) {
                        targetCompany = timeCompanies.find(c => c.company.id === targetCompanyId);
                    }

                    defaultCompany = timeCompanies.find(c => c.company.id === currentUser.defaultCompany);

                    if (!defaultCompany && !isEmpty(timeCompanies)) {
                        defaultCompany = timeCompanies[0];
                    }

                    if (targetCompany) {
                        const companyId = get(targetCompany, 'company.id', null);
                        sessionStorage.setItem('companyId', companyId);
                        setSearchParams({companyId: companyId});
                        const valid = await initCompanyInfo(targetCompany);
                        if (valid)
                            setActiveCompany(targetCompany.company);
                        else {
                            setIsTimeApplicationUser(false);
                            setBootstrapping(false);
                        }
                    }
                    else if (defaultCompany) {
                        const companyId = get(defaultCompany, 'company.id', null);
                        sessionStorage.setItem('companyId', companyId);
                        setSearchParams({companyId: companyId});
                        const valid = await initCompanyInfo(defaultCompany);
                        if (valid)
                            setActiveCompany(defaultCompany.company);
                        else {
                            setIsTimeApplicationUser(false);
                            setBootstrapping(false);
                        }
                    }
                    else {
                        sessionStorage.setItem('companyId', '');
                        setBootstrapping(false);
                    }
                }
                else {
                    sessionStorage.setItem('companyId', '');
                    setIsTimeApplicationUser(false);
                    setBootstrapping(false);
                }
            }
        };
        init();
    }, [companies, currentUser.id]);

    useEffect(() => {
        if (companyInfo.companyName) {
            setCompanyId(companyInfo.companyId);
        }
    }, [companyInfo.companyId]);

    useEffect(() => {
        if (timeUserSettings && timeUserSettings.viewData) {
            const viewData = JSON.parse(timeUserSettings.viewData);
            i18n.changeLanguage(viewData.selectedLanguage);
        }
        else {
            i18n.changeLanguage('en');
        }
    }, [timeUserSettings?.viewData]);

    return (
        <>
            {
                changePasswordVisible ?
                    <ChangePassword
                        visible={changePasswordVisible}
                        setVisible={setChangePasswordVisible}
                    />
                    : null
            }
            { session ?
                <WebSocketConnection
                    companyId={companyId}
                    idToken={`${session.idToken}`}
                    instanceId={instanceId}
                />
                : null
            }
            {
                isInEditMode ? <Beforeunload onBeforeunload={event => event.returnValue = 'editing'} /> : null
            }
            <SideNavBarLayout
                activeCompany={activeCompany}
                currentOS={currentOS}
                currentUser={currentUser}
                companyUser={companyUser}
                featureFlags={featureFlags}
                isBootStrapping={isBootStrapping}
                isMobileApp={isMobileApp}
                isTimeUser={isTimeApplicationUser}
                settings={settings}
                setUserLanguage={setUserLanguage}
                title={'Signature Time'}
                timeUserSettings={timeUserSettings}
            >
                { isTimeApplicationUser ?
                    <Routes>
                        {
                            routes.map(({path, element}) => (
                                <Route
                                    key={path}
                                    path={path}
                                    element={
                                        <RequireAuth>
                                            {element}
                                        </RequireAuth>
                                    }
                                />
                            ))
                        }
                        <Route
                            path="*"
                            element={
                                <RequireAuth>
                                    {
                                        returnUrl && returnUrl.length > 0 ?
                                            <Navigate to={returnUrl} />
                                            :
                                            activeCompany.id ?
                                                <Navigate to={`/timeentry?companyId=${activeCompany.id}`} />
                                                :
                                                <Navigate to={'/timeentry'} />
                                    }
                                </RequireAuth>
                            }
                        />
                    </Routes>
                    :
                    <Routes>
                        <Route
                            key="/accessdenied"
                            path="/accessdenied"
                            element={
                                <AccessDenied
                                    activeCompany={activeCompany}
                                    currentOS={currentOS}
                                    currentUser={currentUser}
                                    isMobileApp={isMobileApp}
                                />
                            }
                        />
                        <Route
                            path="*"
                            element={
                                <Navigate to="/accessdenied" />
                            }
                        />
                    </Routes>
                }
                <Footer>
                    Copyright &copy; {getYear(new Date())} WennSoft, Inc. All rights reserved.<br />Version: {apiVersion}
                </Footer>
            </SideNavBarLayout>
        </>
    );
};

const mapStateToProps = state => {
    return {
        activeCompany:         state.time.activeCompany,
        activeEmployee:        state.employee.activeEmployeeId,
        apiVersion:            state.core.apiVersion,
        changePasswordVisible: state.time.changePasswordVisible,
        companies:             state.core.companies,
        companyUser:           state.companyUser.companyUser,
        companyInfo:           state.companyInfo,
        currentOS:             state.core.currentOS,
        currentUser:           state.currentUser,
        featureFlags:          state.core.featureFlags,
        instanceId:            state.auth.instanceId,
        isBootStrapping:       state.core.isBootStrapping,
        isInEditMode:          state.time.isInEditMode,
        isMobileApp:           state.core.isMobileApp,
        isTimeApplicationUser: state.companyUser.isTimeApplicationUser,
        session:               state.auth.session,
        settings:              state.time.settings,
        timeCompanies:         state.core.timeCompanies,
        timeUserSettings:      state.configurations.timeUserSettings,
        workOrders:            state.workOrder.workOrders
    };
};

const mapDispatchToProps = {
    ...ActivitiesActions,
    ...CompanyUserActions,
    ...CompanyInfoActions,
    ...ConfigurationActions,
    ...CoreActions,
    ...CurrentUserActions,
    ...EmployeeActions,
    ...JobActions,
    ...WorkOrderActions,
    ...TimeActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Time);
