export const FETCH_ALL_SCOPES ='FETCH_ALL_SCOPES';
export const FETCH_ANNOTATIONS = 'FETCH_ANNOTATIONS';
export const FETCH_API_VERSION = 'FETCH_API_VERSION';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANY_INFO = 'FETCH_COMPANY_INFO';
export const FETCH_COMPANY_PAYCODES = 'FETCH_COMPANY_PAYCODES';
export const FETCH_COMPANY_USER = 'FETCH_COMPANY_USER';
export const FETCH_CONFIGURATION_DATA = 'FETCH_CONFIGURATION_DATA';
export const FETCH_CURRENT_PERIOD = 'FETCH_CURRENT_PERIOD';
export const FETCH_CURRENT_USER_ROLE = 'FETCH_CURRENT_USER_ROLE';
export const FETCH_DEFAULT_COMPANY = 'FETCH_DEFAULT_COMPANY';
export const FETCH_EMPLOYEE_DELEGATE_LIST = 'FETCH_EMPLOYEE_DELEGATE_LIST';
export const FETCH_EMPLOYEE_LIST = 'FETCH_EMPLOYEE_LIST';
export const FETCH_INTEGRATIONS = 'FETCH_INTEGRATIONS';
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_TIME_COMPANIES = 'FETCH_TIME_COMPANIES';
export const FETCH_TIME_SETTINGS = 'FETCH_TIME_SETTINGS';
export const FETCH_USER_SETTINGS = 'FETCH_USER_SETTINGS';
export const IS_SIGNED_IN = 'IS_SIGNED_IN';
export const IS_SIGNED_OUT = 'IS_SIGNED_OUT';
export const REDUX_WEBSOCKET_BEGIN_RECONNECT = 'REDUX_WEBSOCKET::BEGIN_RECONNECT';
export const REDUX_WEBSOCKET_BROKEN = 'REDUX_WEBSOCKET::BROKEN';
export const REDUX_WEBSOCKET_CLOSED = 'REDUX_WEBSOCKET::CLOSED';
export const REDUX_WEBSOCKET_CONNECT = 'REDUX_WEBSOCKET::CONNECT';
export const REDUX_WEBSOCKET_ERROR = 'REDUX_WEBSOCKET::ERROR';
export const REDUX_WEBSOCKET_MESSAGE = 'REDUX_WEBSOCKET::MESSAGE';
export const REDUX_WEBSOCKET_OPEN = 'REDUX_WEBSOCKET::OPEN';
export const REDUX_WEBSOCKET_RECONNECTED = 'REDUX_WEBSOCKET::RECONNECTED';
export const REDUX_WEBSOCKET_RECONNECT_ATTEMPT = 'REDUX_WEBSOCKET::RECONNECT_ATTEMPT';
export const REDUX_WEBSOCKET_SEND = 'REDUX_WEBSOCKET::SEND';
export const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY';
export const SET_ACTIVE_EMPLOYEE = 'SET_ACTIVE_EMPLOYEE';
export const SET_ALL_ROLES = 'SET_ALL_ROLES';
export const SET_API_VERSION = 'SET_API_VERSION';
export const SET_BOOTSTRAPPING = 'IS_BOOTSTRAPPING';
export const SET_CHANGE_PASSWORD_VISIBLE = 'SET_CHANGE_PASSWORD_VISIBLE';
export const SET_COMPANY_SHIFT_CODES = 'SET_COMPANY_SHIFT_CODES';
export const SET_CURRENT_WEEKENDING_DATE = 'SET_CURRENT_WEEKENDING_DATE';
export const SET_CURRENT_OS = 'SET_CURRENT_OS';
export const SET_CURRENT_SCOPES = 'SET_CURRENT_SCOPES';
export const SET_CURRENT_TIMESHEET = 'SET_CURRENT_TIMESHEET';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_EMPLOYEE_PAYCODES = 'SET_EMPLOYEE_PAYCODES';
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';
export const SET_IS_IN_EDIT_MODE = 'SET_IS_IN_EDIT_MODE';
export const SET_IS_IN_REFRESH = 'SET_IS_IN_REFRESH';
export const SET_IS_LOADING_TIMESHEET = 'SET_IS_LOADING_TIMESHEET';
export const SET_IS_SAVE_VALID = 'SET_IS_SAVE_VALID';
export const SET_IS_TIME_APPLICATION_USER = 'SET_IS_TIME_APPLICATION_USER';
export const SET_JOB_LIST = 'SET_JOB_LIST';
export const SET_JOB_COST_CODES = 'SET_JOB_COST_CODES';
export const SET_DAYS_FOR_PERIOD = 'SET_DAYS_FOR_PERIOD';
export const SET_ROLE = 'SET_ROLE';
export const SET_WORK_ORDER_LIST = 'SET_WORK_ORDER_LIST';
export const SET_WORK_ORDER_COST_CODES = 'SET_WORK_ORDER_COST_CODES';
export const SET_TIMESHEET_DATA = 'SET_TIMESHEET_DATA';
export const SET_TIMESHEET_GRID_INSTANCE = 'SET_TIMESHEET_GRID_INSTANCE';
export const SET_TIMESHEET_START_DATE = 'SET_TIMESHEET_START_DATE';
export const SET_UNBILLED_ACTIVITIES = 'SET_UNBILLED_ACTIVITIES';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const USER_LOGOUT = 'USER_LOGOUT';
